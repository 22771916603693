import * as React from "react"
import { Header, BlogPostOpen, Footer, BottomNavbar } from "../components/index"
// import { Layout } from "../components/index"

import { Helmet } from "react-helmet"
import mockup from "../utils/data"

export default function blogPost({ pageContext }) {
  const {
    slug,
    id,
    published,
    title,
    category,
    author,
    body,
    image,
    recentPosts,
  } = pageContext

  console.log(pageContext)

  return (
    <div>
      <Helmet>
        <title>Esteekey - Blog Post</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="pt-[69px] xl:pt-0">
        <BlogPostOpen
          title={title}
          overview={pageContext.preview || mockup.blog_post.overview}
          publish={published}
          url={slug}
          list={recentPosts}
          image={image}
          content={body}
        />
        <BottomNavbar />
        <Footer />
      </div>
    </div>
  )
}
